/* shop-card */
.shop-card {
    max-height: 313px;
    max-width: 216px;
    margin-right: 3rem;
    margin-top: 3rem;
    cursor: pointer;
  }
  
  .shop-card span {
    color: #e4c8a0;
    font-family: Lato;
    font-size: 17.27px;
    font-weight: 600;
  }
  
  .shop-card p {
    margin-bottom: 1rem;
    margin-top: 1rem;
    color: #22232b;
    font-family: Lato;
    font-size: 15.35px;
    font-weight: bold;
  }
  
  .img-shop-card {
    height: 216px;
    width: 216px;
    padding: 5px;
    border-radius: 7px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
  }
  
  .btn-add-basket {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 34.83px;
    width: 35.92px;
    border-radius: 4.84px;
    background-color: #e4c8a0;
  }
  
  .btn-add-basket:hover {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
  }
  /* shop-card */
  