/* selection */
@media only screen and (max-width: 800px) {
  .shop-list {
    justify-content: center;
    align-items: center;
  }

  .shop-card {
    max-width: 130px !important;
    margin-right: 1rem !important;
  }

  .img-shop-card {
    height: 130px !important;
    width: 130px !important;
  }

  .image-shopcard {
    height: 130px !important;
    width: 130px !important;
  }

  .shop-card p {
    margin-bottom: 1rem;
    margin-top: 1rem;
    color: #22232b;
    font-family: Lato;
    font-size: 15.35px;
    font-weight: bold;
    /* border: solid 2px blue; */
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 135px;
    display: block;
    overflow: hidden;
    height: 22px;
  }
}

.image-shopcard {
  height: 216px;
  width: 216px;
  object-fit: contain;
}



.selection h2 {
  color: #0a2e2f;
  font-family: "Arapey";
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 0px;
}

.selection p {
  opacity: 0.52;
  color: #041212;
  font-family: Lato;
  font-size: 20px;
  margin-top: 0px;
}

.selection-btn {
  margin-top: 2rem;
  color: #585858;
  font-family: Lato;
  font-size: 17px;
  font-weight: 500;
  border-radius: 6px;
  background-color: #e4c8a0;
  padding: 7px;
  cursor: pointer;
}

.selection-btn-retry {
  margin-top: 2rem;
  color: #ffffff;
  font-family: Lato;
  font-size: 17px;
  font-weight: 500;
  border-radius: 6px;
  background-color: #a0a0a0;
  padding: 7px;
  cursor: pointer;
}

.selection-btn-retry:hover {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
}

.selection-btn:hover {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
}

/* selection */


/* shop */
.shop {
  margin-top: 2rem;
}

.shop h2 {
  color: #0a2e2f;
  font-family: "Arapey";
  font-size: 45px;
  font-weight: bold;
}
.shop p {
  opacity: 0.52;
  color: #041212;
  font-family: Lato;
  font-size: 20px;
  margin-top: 0px;
  padding-right: 50px;
}

.shop-list {
  display: flex;
  flex-wrap: wrap;
}
/* shop */

/* filter */
.filter {
  margin-top: 2rem;
  height: 268px;
  width: 197px;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 0 11px 0 rgba(49, 49, 49, 0.1);
  margin-right: 3rem;
  padding: 1rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.filter p {
  opacity: 0.5;
  color: #22232b;
  font-family: Lato;
  font-size: 12.47px;
  margin-top: 0;
}

.filter-label {
  color: #22232b;
  font-family: Lato;
  font-size: 14px;
}

.input-range__slider {
  background: #308588 !important;
  border: 1px solid #308588 !important;
}

.input-range__track--active {
  background:  #308588 !important;
}
/* .filter input {
    margin-right: 1rem;
} */

.filter input {
  box-sizing: border-box;
  height: 15px;
  width: 15px;
  /* border: 1px solid #5AC4C1; */
  border-radius: 3px;
  margin-right: 1rem;
  /* outline: 1px solid #5AC4C1; */
}

.MuiSelect-select.MuiSelect-select {
  padding-right: 80px !important;
  width: 110px !important;
}
/* filter */


/* basket hidden */

.basket-drawer {
  background-color: #308588;
  text-align: center;
  padding: 2rem;
}

.basket-drawer h2 {
  color: #ffffff;
  font-family: "Arapey";
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 4rem;
}

.basket-drawer p {
  opacity: 0.7;
  color: #ffffff;
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
}



.MuiDrawer-paperAnchorRight {
  background-color: #308588 !important;

  width: 300px;
}
/* basket hidden */
