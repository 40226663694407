.pack-page {
  /* padding-right: 14%; */
  padding-left: 10%;
  padding-top: 1rem;
}

.pack-page-title {
  color: #0a2e2f;
  font-family: "Arapey";
  font-size: 60px;
  font-weight: bold;
}

.selected-type-pack {
  cursor: pointer;
  margin-right: 1rem;
  border-radius: 7px;
  padding: 10px;
  background-color: #5ac4c1;
}

.selected-type-pack h2 {
  color: #ffffff;
  margin: 0;
  font-family: Lato;
  font-size: 19px;
  font-weight: bold;
}

.unselected-type-pack {
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-right: 1rem;
  border-radius: 7px;
  padding: 10px;
  border: 1px solid #d6d6d6;
}

.unselected-type-pack h2 {
  color: #bdbdbd;
  font-family: Lato;
  margin: 0;
  font-size: 19px;
  font-weight: 400;
}

.select-type {
  display: flex;
  overflow: scroll;
  padding-bottom: 15px;
}

.select-type::-webkit-scrollbar {
  border-radius: 2px;
  height: 4px;
}

.select-type::-webkit-scrollbar-track {
  border-radius: 2rem;
  height: 10px !important;
  background: transparent;
  
}

/* Handle */
.select-type::-webkit-scrollbar-thumb {
  border-radius: 2rem;
  height: 10px !important;
  background: #5ac4c1;
  cursor: pointer;
}

/* Handle on hover */
.select-type::-webkit-scrollbar-thumb:hover {
  background: #5ac4c1; 
  cursor: pointer;
}

.list-pack {
  margin-top: 4rem;
  display: flex;
  overflow-x: scroll;
  padding-bottom: 3rem;
  padding-left: 10px;
}

/* .list-pack::-webkit-scrollbar {
  display: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
} */

.list-pack::-webkit-scrollbar {
  border-radius: 2px;
  /* width: 4px; */
  height: 4px;
  /* padding-left: 10px;
  padding-bottom: 10px; */
}

/* Track */
.list-pack::-webkit-scrollbar-track {
  border-radius: 2rem;
  height: 10px !important;
  background: rgba(0,0,0,0.08);
  
}

/* Handle */
.list-pack::-webkit-scrollbar-thumb {
  border-radius: 2rem;
  height: 10px !important;
  background: #5ac4c1;
  cursor: pointer;
}

/* Handle on hover */
.list-pack::-webkit-scrollbar-thumb:hover {
  background: #5ac4c1; 
  cursor: pointer;
}

.list-pack h2 {
  color: #0a2e2f;
  font-family: "Arapey";
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 8px;
}

.box-price-pack {
  border-radius: 16.12px;
  background-color: #ffffff;
  width: 100px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 13px 0 rgba(178, 204, 203, 0.44);
}

.box-price-pack span {
  color: #5ac4c1;
  font-family: Lato;
  font-size: 15.6px;
  font-weight: 600;
  letter-spacing: 0.19px;
}

.pack-item {
  width: 330px;
  margin-right: 3rem;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.pack-item img {
  height: 363px;
  width: 330px;
  border-radius: 7px;
  object-fit: cover;
}
