.navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.navbar li a {
    display: block;
    font-family: 'Lato';
    color: #728182;
    /* opacity: 60%; */
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.navbar li span {
    display: block;
    font-family: 'Lato';
    color: #728182;
    /* opacity: 60%; */
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    cursor: pointer;
}

.navbar li {
    float: center;
}

.navbar li a:hover {
    font-family: 'Lato';
    font-weight: bold;
    color: #3dacae;
}

.navbar li span:hover {
    font-family: 'Lato';
    font-weight: bold;
    color: #3dacae;
}

.navbar-profiling {
    height: 100px;
    background-color: #FFFFFF;
    box-shadow: 0 0 17px 0 rgba(0,0,0,0.18);
    justify-content: center;
    align-content: center;
    display: flex;
}

.MuiLinearProgress-barColorPrimary {
    background-color: #41AFB2 !important;
}

.MuiLinearProgress-colorPrimary {
    background-color: #D8D8D8 !important;
}

.MuiLinearProgress-root {
    height: 8px !important;
    overflow: hidden;
    position: relative;
}