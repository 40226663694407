@media only screen and (max-width: 800px) {
  .container-item-page {
    width: 100%;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
  }

  .container-left-ip {
    width: 100% !important;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .carousel-responsive-size {
    width: 50vw !important;
  }

  .container-right-ip {
    width: 100% !important;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 0 !important;
  }

  .item-page {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
    padding-top: 3rem;
  }

  .image-custom {
    border-radius: 10px;
    height: 190px !important;
    width: 100%;
    object-fit: cover;
  }
}

.carousel-responsive-size {
  width: 20vw;
}

.container-left-ip {
  width: 20vw;
}
.container-right-ip {
  width: 80vw;
  padding-left: 10rem;
}

.container-item-page {
  width: 100%;
  margin-top: 5rem;
  display: flex;
}

.item-page {
  padding-right: 14%;
  padding-left: 14%;
  padding-top: 3rem;
}

.path {
  opacity: 0.64;
  color: #022a2c;
  font-family: Lato;
  font-size: 14px;
}

.image-custom {
  border-radius: 10px;
  height: 45vh;
  width: 100%;
  object-fit: cover;
}

.back-button {
  color: #022a2c;
  cursor: pointer;
  margin-right: 5px;
}

.back-button-dot {
  color: #022a2c;
  cursor: pointer;
  margin-right: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 135px;
  display: block;
  overflow: hidden;
}
.back-button:hover {
  font-weight: bold;
  letter-spacing: -1px;
}

.item-page h1 {
  color: #0a2e2f;
  /* font-family: " 72"; */
  font-family: "Arapey";
  font-size: 35px;
  font-weight: bold;
}

.item-page p {
  opacity: 0.52;
  color: #041212;
  font-family: Lato;
  font-size: 20px;
}

.price {
  color: #e4c8a0;
  font-family: Lato;
  font-size: 25px;
  font-weight: bold;
}

.backet {
  height: 49px;
  width: 52px;
  border-radius: 7px;
  background-color: #e4c8a0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
  cursor: pointer;
}

.backet-disable {
  height: 49px;
  width: 52px;
  border-radius: 7px;
  background-color: #a0a0a0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
  cursor: pointer;
}
.backet:hover {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
}

.image-slide {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  height: 65px;
  width: 65px;
  object-fit: cover;
}

.MuiAlert-filledSuccess {
  background-color: #5ac4c1 !important;
}
