@media only screen and (max-width: 800px) {
    .giftcard-page {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .gifcard-img-container {
        width: 100% !important;
        justify-content: center;
    }

    .gifcard-info-container {
        width: 100% !important;
    }

    .giftcard-page {
        padding: 1rem !important;
    }
}

.gifcard-info-container {
    width: 55%;
}
.giftcard-page {
    padding: 6rem;
    display: flex;
    position: relative;
}

.giftcard-page h1 {
    color: #0a2e2f;
    font-family: "Arapey";
    font-size: 35px;
    font-weight: bold;
}

.giftcard-page p {
    margin-top: 3rem;
    opacity: 0.52;
    color: #041212;
    font-family: Lato;
    font-size: 20px;
}

.giftcard-page span {
    color: #E4C8A0;
    font-family: Lato;
    font-size: 25px;
    font-weight: bold;
}

.gifcard-img-container {
    width: 45%;
    display: flex;
    align-items: center;
}


.giftcard {
    width: 70%;
}

.custom-input-amount {
    height: 44px;
    width: 224px;
    border: 1.5px solid #fcf9f5;
    /* opacity: 0.36; */
    border-radius: 22px;
    background-color: #fcf9f5;
    padding-left: 10px;
}

.custom-input-amount::placeholder {
    color: #c7af8c;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0;
}

.list-gift li {
    opacity: 0.52;
    color: #041212;
    font-family: Lato;
    font-size: 20px;
}
