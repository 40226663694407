/* quiz */
.color-btn {
  background-color: #41afb2 !important;
  color: #ffffff;
}
@media only screen and (max-width: 800px) {
  .quiz {
    width: 100% !important;
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .quiz-container {
    margin-right: 0 !important;
  }

  .quiz-img {
    height: 45% !important;
    width: 45% !important;
  }

  .image-list {
    padding: 1rem !important;
  }
}

.quiz-container {
  margin-right: 2rem;
}
.quiz-mobile {
  width: 90%;
  text-align: center;
  background-color: white;
  border-radius: 20px;
}
.quiz {
  text-align: center;
  background-color: white;
  padding: 2rem;
  border-radius: 20px;
}

.quiz-mobile h1 {
  color: #308588;
  font-family: "Arapey";
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
}

.quiz-mobile p {
  padding-right: 20px;
  padding-left: 20px;
  color: #041212;
  font-family: Lato;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16px;
  opacity: .52;
  text-align: justify;
  text-justify: inter-word;
}

.title-quizz {
  color: #41afb2;
}

.quiz h1 {
  color: #308588;
  font-family: "Arapey";
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
}

.quiz p {
  color: #041212;
  font-family: Lato;
  font-size: 14px;
  opacity: .52;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16px;
  text-align: justify;
  text-justify: inter-word;
}

.subtitle-quiz {
  color: #041212;
  font-family: Lato;
  font-size: 14px;
  opacity: .52;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.quiz-img {
  height: 180px;
  width: 180px;
  border-radius: 16px;
  object-fit: cover;
  cursor: pointer;
  margin-bottom: 2rem;
  padding: 5px;
}

.image-list {
  display: flex;
  flex-wrap: wrap;
  padding: 3rem;
  justify-content: space-around;
}

.border-select {
  border: #308588 solid 6px;
}

.email-input {
  border: 1px solid #bebebe;
  border-radius: 7px;
  padding: 0.5rem;
  margin-top: 1rem;
}
/* quiz */
