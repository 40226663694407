/* footer */
.footer {
    margin-top: 10rem;
  }
  
  .wrap-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3rem;
  }
  
  
  .footer span  {
    color: #3D4F58;
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.36px;
  }
  
  .footer li {
    opacity: 0.78;
    color: #607D8B !important;
    font-family: Lato;
    font-size: 18px;
    font-weight: 200;
    letter-spacing: 0.64px;
    line-height: 25px;
  }
  
  .footer li:hover {
    color: #5ac4c1;
  }
  
  a {
    text-decoration: none !important;
    color: #607D8B !important;
  }
  
  .footer ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-copyright-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 12%;
    padding-left: 12%;
  }
  
  .footer-copyright-wrap span {
    color: #607D8B;
    font-family: Lato;
    font-size: 14px;
  }
  
  .footer hr {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  /* footer */