.stripe-success {
  background: linear-gradient(to bottom, rgba(255,255,255,0) 70%, rgba(255,255,255,1) 100%),
  url("../../assets/stripe-success/bg_mockup_app.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto, cover;
  width: 100%;
}

.stripe-success__container {
  padding: 30px 20vw;
  width: calc(100% - 40vw);
}

.stripe-success__border {
  background: white;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 60px 10vw;
}

.stripe-success__title {
  color: rgb(0, 0, 0);
  font-size: 18px;
  font-family: "Lato-Bold", sans-serif;
  font-weight: bold;
  letter-spacing: 0.01px;
  margin-bottom: 20px;
  text-align: center;
}

.stripe-success__warning {
  color: rgb(106, 106, 106);
  font-size: 14px;
  font-family: "Lato-Bold", sans-serif;
  font-weight: normal;
  letter-spacing: 0.01px;
  margin-top: 10px;
  text-align: center;
}

.stripe-success__description {
  color: rgb(90, 196, 193);
  font-size: 16px;
  font-family: "Lato-Regular", sans-serif;
  font-weight: bold;
  letter-spacing: 0.01px;
  text-align: center;
}
