.profiling-page {
  /* display: flex; */
  padding-top: 3rem;
  padding-bottom: 2rem;
  justify-content: center;
}

.profiling-page-padding {
  padding-right: 100px;
  padding-left: 100px;
}

.flex {
  display: flex;
}


button:focus {outline:0;}