.tracking-card {
  /* height: 309px; */
  width: 100%;
  padding: 3rem;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}

.title-tracking {
    color: #0A2E2F;
    font-family: "Arapey";
    font-size: 30px;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.step-title {
  height: 36px;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.01px;
  line-height: 12px;
  text-align: center;
}

.step-line {
  height: 4px;
  width: 20%;
  background-color: #808285;
  margin-top: 22px;
}

.title-card-track {
  color: #000000;
  font-family: Lato;
  font-size: 25px;
  font-weight: bold;
}

.commande-number {
  color: #5ac4c1;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 6rem;
}
