.home-presentation {
  width: 590px;
}

.text-shema {
  width: 25%;
}

@media only screen and (max-width: 900px) {
  .twin {
    display: none;
  }

  .twin-2 {
    display: block !important;
    margin-top: 5rem;
  }

  .text-us {
    font-size: 10px !important;
  }

  .margin-background-icon-list {
    margin-top: 46px !important;
  }

  .padding-first {
    padding-top: 0 !important;
  }

  .heignt-us {
    height: 1250px !important;
  }

  .w-text-us {
    width: 100% !important;
  }

}

.w-text-us {
  width: 55%;
}

.heignt-us {
  height: 860px;
}

.padding-first {
  padding-top: 5rem;
}

.margin-background-icon-list {
  margin-top: -80px;
}

.twin-2 {
  display: none;
}

.text-presentation {
  color: #0a2e2f;
  font-family: "Arapey";
  font-weight: bold;
}

.description-presentation {
  opacity: 0.52;
  color: #041212;
  font-family: Lato;
  /* font-size: 20px; */
  line-height: 24px;
  max-width: 571px;
}

.description-presentation-mobile {
  opacity: 0.52;
  color: #041212;
  font-family: Lato;
  /* font-size: 20px; */
  max-width: 571px;
}

.wrap-btn-presentation {
  width: 100%;
  text-align: center;
}


.wrap-top-home {
  display: flex;
  padding-top: 10rem;
  padding-left: 18rem
}

.wrap-top-home-responsive {
  display: flex;
  padding-top: 10rem;
  padding-left: 0;
  text-align: center;
}

/* step */
.steps-wrap {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.step {
  width: 20%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 230px;
}

.number-step {
  color: #5ac4c1;
  font-family: "Arapey";
  font-size: 30px;
  font-weight: bold;
}

.description-step {
    opacity: 0.88;
    color: #041212;
    font-family: Lato;
    font-size: 16px;
}

.wrap-icon-step {
  box-sizing: border-box;
  height: 115px;
  width: 115px;
  border: 2px solid #5ac4c1;
  background-color: white ;
  border-radius: 56.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap-icon-delivery {
  height: 75px;
  width: 75px;
  border-radius: 56.5px;
  background-color: #5AC4C1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.green-line {
  width: 5%;
  box-sizing: border-box;
  height: 2px;
  width: 63%;
  margin-top: 12px;
  margin-top: 60px;
  border: 1px dashed #5AC4C1;
}
/* step */

/* pack */
.pack-title {
  color: #0A2E2F;
  font-family: "Arapey";
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 6rem;
  padding-right: 2rem;
  padding-left: 2rem;
}

.pack {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 19rem;
  position: relative;
}

.pack p {
  /* height: 4rem; */
  opacity: 0.88;
  color: #041212;
  font-family: Lato;
  font-size: 16px;
}

.pack h3 {
  color: #0A2E2F;
  font-family: Lato;
  font-size: 25px;
  font-weight: bold;
}
/* pack */
/* journal */

.journal {
  text-align: center;
}

.journal p {
  opacity: 0.52;
  color: #041212;
  font-family: Lato;
  font-size: 20px;
  margin-bottom: 3rem;
}
.journal-title {
  color: #0A2E2F;
  font-family: "Arapey";
  font-size: 45px;
  font-weight: bold;
}

/* journal */

/* feedback */

.feedback-wrap {
  text-align: center;
  max-width: 529px;
}

.feedback-wrap span {
  color: #50C1BD;
  font-family: Lato;
  font-size: 19px;
  line-height: 23px;
}

.feedback-wrap p {
  margin-top: 1rem;
  color: #041212;
  font-family: Lato;
  font-size: 18px;
  font-weight: 300;
}

.feedback-wrap .feedback-wrap-mobil  {
  margin-top: 1rem;
  color: #041212;
  font-family: Lato;
  font-size: 0.8rem !important;
  opacity: 0.52;
  font-weight: 300;
}
/* feedback */

/* mockup */

.title-mockup {
  color: #0A2E2F;
  font-family: "Arapey";
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 1rem !important;
}

.wrap-mockup {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wrap-mockup p {
  opacity: 0.52;
  margin-top: 0;
  color: #041212;
  font-family: Lato;
  font-size: 20px;
  margin-bottom: 5px;
}

/* mockup */

/* contact */

.wrap-contact {
  text-align: center;
}

.wrap-contact h2 {
  color: #0A2E2F;
  font-family: "Arapey";
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 3rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-form-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-form-mobile input {
  min-height: 26px;
  border: 1px solid #BEBEBE;
  border-radius: 7px;
  margin-bottom: 1rem;
  padding-left: 5px;
  width: 90%;
}

.contact-form input::placeholder {
  opacity: 0.52;
  color: #041212;
  font-family: Lato;
  font-size: 13px;
}

.contact-form-mobile textarea::placeholder {
  opacity: 0.52;
  color: #041212;
  font-family: Lato;
  font-size: 13px;
}

.contact-form-mobile textarea {
  padding: 1rem;
  width: 87%;
  height: 110px;
  border: 1px solid #BEBEBE;
  border-radius: 7px;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.contact-form input {
  min-width: 591px;
  min-height: 43px;
  border: 1px solid #BEBEBE;
  border-radius: 7px;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.contact-form input::placeholder {
  opacity: 0.52;
  color: #041212;
  font-family: Lato;
  font-size: 20px;
}

.contact-form textarea::placeholder {
  opacity: 0.52;
  color: #041212;
  font-family: Lato;
  font-size: 20px;
}

.contact-form textarea {
  padding: 1rem;
  min-width: 591px;
  /* min-height: 53px; */
  height: 205px;
  border: 1px solid #BEBEBE;
  border-radius: 7px;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.text-us {
  opacity: 0.52;
  color: #041212;
  font-family: Lato;
  font-size: 20px;
}

.title-us {
  color: #0A2E2F;
  font-family: "Arapey";
  font-size: 45px;
  font-weight: bold;
}



.contact-responsive input {
  width: 90%;
  min-height: 55px;
  border: 1px solid #BEBEBE;
  border-radius: 7px;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.contact-responsive input::placeholder {
  opacity: 0.52;
  color: #041212;
  font-family: Lato;
  font-size: 20px;
}

.contact-responsive textarea::placeholder {
  opacity: 0.52;
  color: #041212;
  font-family: Lato;
  font-size: 20px;
}

.contact-responsive textarea {
  padding: 1rem;
  width: 90%;
  /* min-height: 53px; */
  height: 205px;
  border: 1px solid #BEBEBE;
  border-radius: 7px;
  padding: 0.5rem;
}


.img-mockup {
  height: 460px;
  width: 696px;
}


.img-mockup-responsive {
  height: 340px;
  width: 369px;
}

/* contact */
