.selection-container {
  display: flex;
  padding-left: 8px;
  overflow-x: scroll;
  padding-right: 1rem;
  width: 85%;
}
.selection-container::-webkit-scrollbar {
  border-radius: 2px;
  height: 4px;
}

.selection-container::-webkit-scrollbar-track {
  border-radius: 2rem;
  height: 10px !important;
  background: transparent;
  
}

/* Handle */
.selection-container::-webkit-scrollbar-thumb {
  border-radius: 2rem;
  height: 10px !important;
  background: #5ac4c1;
  cursor: pointer;
}

/* Handle on hover */
.selection-container::-webkit-scrollbar-thumb:hover {
  background: #5ac4c1; 
  cursor: pointer;
}
.selection-card {
    max-height: 313px;
    max-width: 216px;
    margin-right: 3rem;
    margin-top: 2rem;
    cursor: pointer;
  }
  
  .selection-card span {
    color: #e4c8a0;
    font-family: Lato;
    font-size: 17.27px;
    font-weight: 600;
  }
  
  .selection-card p {
    margin-bottom: 5px;
    margin-top: 1rem;
    color: black;
    opacity: 1;
    font-family: Lato;
    font-size: 15.35px;
    font-weight: bold;
  }

  .add-delete-img {
      position: absolute;
      right: 5px;
      top: 5px;
      width: 30px;
  }
  
  .img-selection-card {
    height: 166px;
    width: 166px;
    border-radius: 7px;
    object-fit: contain;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.11);
    position: relative;
  }

button:focus {outline:0;}