/* fav-wrap */

@media only screen and (max-width: 800px) {
  .section-favorite {
    margin: 0 !important;
  }
}

.MuiAppBar-colorPrimary {
  background-color: white !important;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}
.MuiSvgIcon-root {
  fill: #76C2C0 !important;
}

.section-favorite {
  margin-right: 18vh;
  margin-left: 18vh;
}

.fav-mobile-title {
  font-size: 1.5em;
  margin-top: 1rem;
  color: #0A2E2F;
  font-family: "Arapey";
}

.fav-wrap h2 {
    color: #0A2E2F;
    font-family: "Arapey";
    font-size: 45px;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  
  .fav-wrap p {
    opacity: 0.52;
    color: #041212;
    font-family: Lato;
    font-size: 20px;
    margin-bottom: 4rem;
  }
  
  
  .item-fav span {
    color: #5AC4C1;
    font-family: Lato;
    font-size: 13px;
  }
  
  .item-fav {
    width: 191px;
    text-align: center;
  }
  
  .item-fav p {
    font-family: Lato;
    font-size: 15px;
    font-weight: bold;
    margin: 0;
  }

  .item-fav img {
    height: 216px;
    width: 216px;
    object-fit: contain;
  }
  
  /* fav-wrap */
  