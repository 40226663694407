.recap-container {
  display: flex;
  padding-right: 20%;
  padding-left: 20%;
  margin-top: 4rem;
}

@media only screen and (max-width: 800px) {
  .recap-container {
    display: flex;
    flex-direction: column-reverse;
    padding-right: 2rem;
    padding-left: 2rem;
    margin-top: 4rem;
  }

  .recap {
    width: 100% !important;
  }

  .recap-list-container {
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }
} 

.recap {
  width: 50%;
}

.recap-list-container {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.vl {
  margin-right: 3rem;
  margin-left: 3rem;
  box-sizing: border-box;
  height: 490px;
  width: 1px;
  border: 0.5px solid #979797;
  opacity: 0.7;
}
.recap-list {
  height: 280px;
  overflow: scroll;
}

.recap-list::-webkit-scrollbar {
  display: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.title-recap {
  font-family: "Arapey";
  font-size: 30px;
  font-weight: bold;
  color: #308588;
}

.gift-card-amount {
  align-items: center;
  background: #308588AA;
  border: 2px solid #308588;
  border-radius: 50px;
  color: white;
  display: flex;
  font-family: "Lato-Bold", sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-left: 15px;
  padding: 5px 10px;
  margin-top: 0.5rem;
}

.recap input {
  margin-bottom: 0.5rem;
}

.recap h2 {
  color: #082829;
  font-family: Lato;
  font-size: 14px;
}

.recap label {
  color: #607d8b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.42px;
}

.custom-input-recap {
  height: 30px;
  width: 100% !important;
  width: 270px;
  border: 1.5px solid #f6fafa;
  /* opacity: 0.36; */
  border-radius: 15px;
  background-color: #f6fafa;
}

.custom-input-recap::placeholder {
  color: #797979;
  font-family: Lato;
  font-size: 12px;
  font-weight: 300;
}

.custom-input-discout {
  height: 38px;
  width: 281px;
  border: 1px solid rgba(175, 188, 188, 0.25);
  margin-top: 0.5rem;
}

.custom-input-discout::placeholder {
  opacity: 0.3;
  color: #22232b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
}

/* item-recap */
.item-recap {
}

.title-item-recap {
  margin: 0;
  color: #082829;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
}

.price-item-recap {
  margin: 0;
  color: #00b2b4;
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
}

.hr-recap {
  border: 0.5px solid #979797;
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
/* item-recap */

.sous-total {
  color: #082829;
  font-family: Lato;
  font-size: 14px;
}

.bold {
  font-weight: 600;
}

.shipping {
  color: #082829;
  font-family: Lato;
  font-size: 12px;
}

.total {
  font-family: Lato;
  font-size: 17px;
  font-weight: 600;
}


.delete-item-recap {
  color: black;
  font-family: Lato;
  font-size: 10px;
  letter-spacing: 0;
  text-decoration: underline;
  cursor: pointer;
}

.error {
  color: red;
  font-size: 12px;
}