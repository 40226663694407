/* profil */

.profil {
    height: 650px;
    width: 206px;
    border-radius: 20px;
    background-color: #308588;
    text-align: center;
    padding: 2rem;
  }
  
  .profil h2 {
    color: #ffffff;
    font-family: "Arapey";
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    margin-top: 2rem;
    margin-bottom: 0;
  }
  
  .selected-point {
    height: 34px;
    width: 100%;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 7px;
    background-color: rgba(255, 255, 255, 0.07);
    margin-top: 1.5rem;
  }
  
  .selected-point span {
    color: #ffffff;
    font-family: Lato;
    font-size: 14px;
  }
  
  .them-selected-point {
    height: 73px;
    width: 100%;
    background-position: 50% 50%;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
    /* background-color: rgba(0,0,0,0.39); */
  }
  
  .them-selected-point span {
    color: #ffffff;
    font-family: Lato;
    font-size: 13px;
    letter-spacing: 0;
  }


  .scrol-list-profil {
      height: 519px;
      overflow: scroll;
  }

  .scrol-list-profil-drawer {
    overflow: scroll;
  }

  .scrol-list-profil-drawer::-webkit-scrollbar {
    display: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

  .scrol-list-profil::-webkit-scrollbar {
      display: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      user-select: none;
  }
  
  /* profil */