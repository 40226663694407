/* basket */
.basket {
    min-height: 623px;
    height: 623px;
    border-radius: 20px;
    background-color: #308588;
    text-align: center;
  }
  
  .basket h2 {
    color: #ffffff;
    font-family: "Arapey";
    font-size: 25px;
    font-weight: bold;
  }
  
  .basket p {
    opacity: 0.7;
    color: #ffffff;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
  }
  
  .price-basket {
    color: #FFFFFF;
    font-family: Lato;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
  }
  
  .price-detail {
    opacity: 0.44;
    color: #FFFFFF;
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0;
  }
  
  .total-box {
    margin-top: 2rem;
    height: 41px;
    background-color: rgba(255,255,255,0.1);
    padding-right: 1rem;
    padding-left: 1rem;
  }
  
  .item-list-basket {
    height: 360px;
    overflow-y: scroll;
  }

  .item-list-basket::-webkit-scrollbar {
    display: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
  
  .item-basket {
    display: flex;
  }
  
  .item-basket img {
    height: 67px;
    width: 67px;
    border-radius: 7px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.11);
  }
  
  .item-basket-img-box {
    height: 67px;
    width: 67px;
    border-radius: 7px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.11);
    background-color: white;
  }
  
  .delete-item-basket {
    color: #FFFFFF;
    font-family: Lato;
    font-size: 10px;
    letter-spacing: 0;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .title-item-basket {
    color: #FFFFFF;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
  }
  
  .price-item-basket {
    color: #FFFFFF;
    font-family: Lato;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0;
  }
  
  .nbr-of-item {
    opacity: 0.53;
    color: #FFFFFF;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0;
  }
  
  .hr-item-basket {
    border: 0.5px solid white;
    opacity: 0.33;
    margin-top: 30px;
  }

button:focus {outline:0;}
  /* basket */